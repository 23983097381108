import { Fragment, type ReactNode, useCallback } from 'react';

import { BannerWarningMessages } from '../common/BannerWarningMessage';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { HeaderLayout } from '../Header';
import { LPLogo } from '../icons/LPLogo';
import { Loading } from '../Loading';
import {
  type BasicProgramControlledProps,
  BasicProgramEditor,
} from './BasicProgramEditor';

export function useTriggerCancelConfirmModal() {
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  return useCallback(async () => {
    return await confirmCancel({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-5'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You will lose any unsaved changes.
          </ConfirmCancelModalText>
        </div>
      ),
      confirmBtnVariant: 'delete',
      confirmBtnLabel: 'Continue',
      cancelBtnLabel: 'Go Back',
      autoFocus: 'cancel',
    });
  }, [confirmCancel]);
}

type HeaderProps = {
  onCancel: () => void;
  onSubmit: () => void;
  disabledReason: 'loading' | 'processing' | 'errors' | null;
};

function Header(props: {
  onCancel: () => void;
  onSubmit: () => void;
  disabledReason: 'loading' | 'processing' | 'errors' | null;
}): JSX.Element {
  return (
    <HeaderLayout
      bgClassName={'bg-admin-red'}
      fill
      left={
        <div className='flex items-center gap-4'>
          <LPLogo type='admin' />
        </div>
      }
      right={
        <>
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <div className='relative'>
            <button
              className='btn-primary w-34 h-10 flex flex-row justify-center items-center'
              type='button'
              disabled={!!props.disabledReason}
              onClick={() => {
                props.onSubmit();
              }}
            >
              {props.disabledReason === 'loading' && (
                <Loading
                  text=''
                  containerClassName='mr-2'
                  imgClassName='w-5 h-5'
                />
              )}
              Save
            </button>
          </div>
        </>
      }
    />
  );
}

type ErrorProps = {
  errors: Error[];
  resetErrors: () => void;
};

export function ProgramEditorLayout(
  props: {
    children?: ReactNode;
  } & BasicProgramControlledProps &
    HeaderProps &
    ErrorProps
) {
  const { isDirty } = props.formState;
  const confirmCancel = useTriggerCancelConfirmModal();
  const onCancel = async () => {
    if (isDirty) {
      const response = await confirmCancel();
      if (response.result === 'canceled') return;
    }
    props.onCancel();
  };
  return (
    <Fragment>
      <form className='w-full h-full bg-black fixed flex flex-col z-50 top-0 bg-library'>
        <Header {...props} onCancel={onCancel} />
        <BannerWarningMessages
          errors={props.errors}
          reset={props.resetErrors}
        />
        <div className='flex-grow min-h-0 flex flex-row w-full'>
          <div className='w-1/4 min-w-100 relative overflow-y-auto scrollbar text-white px-7 bg-white bg-opacity-5'>
            <BasicProgramEditor {...props} />
          </div>
          <div className='flex-1 h-full overflow-auto scrollbar'>
            {props.children}
          </div>
        </div>
      </form>
    </Fragment>
  );
}
